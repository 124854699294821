:root {
  --ColorA: #b5b5b5;
  --ColorB: #757575;
  --ColorC: #f5f5f5;
  --ColorD: #363636;
  --ColorE: #dbdbdb;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInTop {
  0% { opacity: 0; transform: translate3d(0, -50px, 0);}
  100% { opacity: 1; transform: translate3d(0, -0, 0);}
}

img{
  display: initial;
}

.MImg{
  display: none;
}

body {
  font-family: "Dosis", sans-serif;
  background-color: var(--ColorC);
  color: var(--ColorD);
}

nav {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.NavLinksDiv {
  display: flex;
  justify-content: space-around;
}

a {
  font-size: 1.6em;
  text-decoration: underline;
  cursor: pointer;
}

.NavLinks {
  margin-right: 25px;
  color: var(--ColorD);
  background: none;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  text-decoration: underline;
  font-size: 2em;
}

p {
  font-size: 1.6em;
}

nav a img{
  width: 160px;
}

#AboutMe {
  width: 80%;
  margin: 100px auto;
  text-align: left;
  display: flex;
  padding: 50px;
  animation: fadeInTop 1.2s;
}

h1 {
  font-size: 2em;
}
.Skill {
  width: 24%;
  background-color: var(--ColorE);
  margin: 50px;
  padding: 30px;
  text-align: left;
  color: var(--ColorD);
  border: solid var(--ColorD);
  border-radius: 5px;
  box-shadow: 10px 10px;
}

#Skills {
  display: flex;
  margin: 50px auto;
}

#Projects {
  display: flex;
  margin-bottom: 100px;
}

#Recent {
  width: 60%;
}
#Recent article {
  display: flex;
  border: solid var(--ColorD);
  border-radius: 5px;
  background-color: var(--ColorE);
  margin: 20px;
  height: auto;
  padding: 20px;
}
footer {
  text-align: center;
}
footer a {
  color: var(--ColorD);
  margin: 20px;
}
form button {
  border-color: var(--ColorB);
  background-color: var(--ColorB);
  color: var(--ColorC);
  border-radius: 5px;
  font-weight: bold;
  font-family: "Dosis", sans-serif;
  font-size: large;
  margin: 20px;
}

#Filter {
  width: 5%;
  margin: 5% 4%;
  padding: 5% 4%;
  display:initial;
}

#OFilter{
  display: none;
}

.ProjectsList {
  width: 70%;
  margin-top: 4%;
}
.ProjectsList article {
  display: flex;
  border: solid var(--ColorD);
  border-radius: 5px;
  background-color: var(--ColorE);
  margin: 36px;
  box-shadow: 10px 10px;
  padding: 30px;
}

.ProjectsList article:hover {
  background-color: #d1d1d1;
}


#Filter select {
  font-family: "Dosis", sans-serif;
  color: var(--ColorC);
  border: solid var(--ColorD);
  border-radius: 5px;
  font-family: "Dosis", sans-serif;
  font-size: 2em;
  background-color: var(--ColorA);
}

#OFilter select {
  font-family: "Dosis", sans-serif;
  color: var(--ColorC);
  border: solid var(--ColorD);
  border-radius: 5px;
  font-family: "Dosis", sans-serif;
  font-size: 2em;
  background-color: var(--ColorA);
}

.DrumsArticles {
  align-items: center;
  width: 60%;
  margin: 5% auto;
}

.DrumsArticles article {
  border: solid var(--ColorD) 4px;
  border-radius: 5px;
  background-color: var(--ColorE);
  margin: 40px;
  width: 800px;
  box-shadow: 8px 8px;
}

.DrumsArticles article video,
img {
  width: 800px;
}

.contact {
  width: 50%;
  margin: 5% auto;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.contact textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

.contact input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.contact input[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact input[type="submit"]:hover {
  background-color: #45a049;
}
.contact label {
  font-size: 1.5em;
}
.contact input {
  font-family: "Dosis", sans-serif;
}
.contact textarea {
  font-family: "Dosis", sans-serif;
}

.HeadTitle {
  margin-left: 20px;
  font-size: 3em;
}

.slidershow{
  width: 800px;
  height: auto;
  overflow: hidden;
  background-color: black;
}

.Hslidershow{
  width: 600px;
  height: auto;
  overflow: hidden;
  background-color: black;
}

.Page{
  width: 80%;
  margin: 24px auto;
}

.Updates{
  margin: 24px auto;
}

.ProjectsList{
  width: 65%;
  margin: 20px 10%;
}

@media only screen and (max-width: 600px) {

.DrumsArticles article h1{
  font-size: 1em;
}
.Skill {
  width: 40%;
  background-color: var(--ColorE);
  margin: 25px auto;
  padding: 10px;
  text-align: left;
  color: var(--ColorD);
  animation: fadeIn 0.8s;
  border: solid var(--ColorD);
  border-radius: 5px;
}

section article img{
  display: none;
}


  nav a img{
    width: 60px;
  }

  .NavLinks {
    font-size: 1em;
    margin-right: 8px;
    margin-left: 14px;
    color: var(--ColorD);
  }

  p {
    font-size: 1.2em;
  }

  h1 {
    font-size: 1.4em;
  }

  #AboutMe {
    width: 100%;
    margin: 32px auto;
    text-align: left;
    display: flex;
    padding: 14px;
  }

  a {
    font-size: 1em;
  }

  .ProjectsList {
    width: 100%;
    margin-top: 4%;
  }
  .ProjectsList article {
    width: 90%;
    display: flex;
    border: solid var(--ColorD);
    border-radius: 5px;
    background-color: var(--ColorE);
    margin: 36px;
    box-shadow: 10px 10px;
    padding: 5%;
    padding-right: 10%;
  }

  #Skills {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  #Recent {
    width: 80%;
  }
  #Recent article {
    display: flex;
    border: solid var(--ColorD);
    border-radius: 5px;
    background-color: var(--ColorE);
    margin: 14px;
    height: auto;
    padding: 20px;
  }

  #Recent {
    width: 100%;
  }

  .ProjectsList {
    width: 100%;
    margin-top: 4%;
  }

  .HeadTitle {
    margin-left: 14px;
    font-size: 1.6em;
  }
  #Filter {
    display:none;
  }
  
  #OFilter{
    display: initial;
    width: 5%;
    margin: 5% 4%;
    padding: 5% 4%;
    margin-bottom: 200px;
    font-size: .8em;
  }

  .DrumsArticles article video,
  img {
    width: 320px;
  }

  .DrumsArticles article {
    width: 320px;
  }

  .slidershow{
    width: 320px;
  }

  .Hslidershow{
    width: 220px;
  }

  section article img{
    display: none;
  }

  #AboutMeImg{
    display: none;
  }

  .MImg{
    display: initial;
  }



}
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .NavLinks {
    font-size: 1.3em;
    margin-right: 10px;
    margin-left: 20px;
    color: var(--ColorD);
  }

  p {
    font-size: 1.5em;
  }

  h1 {
    font-size: 1.8em;
  }

  #AboutMe {
    width: 100%;
    margin: 60px auto;
    text-align: left;
    display: flex;
    padding: 20px;
  }

  a {
    font-size: 1.4em;
  }

  .Skill {
    width: 40%;
    background-color: var(--ColorE);
    margin: 25px auto;
    padding: 15px;
    text-align: left;
    color: var(--ColorD);
    animation: fadeIn 0.8s;
    border: solid var(--ColorD);
    border-radius: 5px;
  }

  #Skills {
    display: flex;
    flex-wrap: wrap;
  }

  #Recent {
    width: 80%;
  }
  #Recent article {
    display: flex;
    border: solid var(--ColorD);
    border-radius: 5px;
    background-color: var(--ColorE);
    margin: 20px;
    height: auto;
    padding: 20px;
  }

  #Recent {
    width: 100%;
  }

  .ProjectsList {
    width: 100%;
    margin-top: 4%;
  }

  .HeadTitle {
    margin-left: 20px;
    font-size: 2em;
  }
  #Filter {
    display:none;
  }
  
  #OFilter{
    display: initial;
    width: 5%;
    margin: 5% 4%;
    padding: 5% 4%;
    margin-bottom: 200px;
    font-size: 1.2em;
  }

  .DrumsArticles article video,
  img {
    width: 540px;
  }

  .DrumsArticles article {
    width: 540px;
  }

  .slidershow{
    width: 540px;
  }

  .Hslidershow{
    width: 320px;
  }



}
