.container-slider {
  max-width: 1000px;
  height: 700px;
  margin: 100px auto 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.vertical-slider{/*
  max-width: 1000px;
  height: 700px*/
}

.horizontal-slider{/*
  max-height: 50%;
  width: 300px
*/
}

@media screen and (max-width: 700px){
  .container-slider {
    width: 400px;
    max-height: 400px;
    margin: 100px 10px 0;
  }
  .slide img {
    width: 40%;
    height: 40%;
    object-fit: contain;
  }
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.slide video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}


.container-dots {
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}
